import React from "react";
import { NavLink } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";

/**
 * Renders a dropdown button with child buttons for logging in with a popup or redirect
 */
export const MenuButtons = (props) => {
    const { instance, accounts } = useMsal();
    const disablePrices = !accounts.some(
        (v) => v.username.match(/(supple|lawrence|hernandez|weilbacher|mudgil)/i)
    );
    // console.log(disablePrices);
    return (
        <>
            <DropdownButton
                variant="danger"
                className="ml-auto"
                drop="left"
                title="Report"
            >
                <NavLink href="/sales-report">
                    <Dropdown.Item as={Button}>Sales Report</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton
                variant="danger"
                className="ml-auto"
                drop="left"
                title="Brokers"
            >
                <NavLink href="/broker-mnt">
                    <Dropdown.Item as={Button}>Broker Management</Dropdown.Item>
                </NavLink>
                <NavLink href="/broker-product-list">
                    <Dropdown.Item as={Button}>Broker Product List</Dropdown.Item>
                </NavLink>
                <NavLink href="/broker-api-enrollments">
                    <Dropdown.Item as={Button}>API Enrollments</Dropdown.Item>
                </NavLink>
                <NavLink href="/broker-api-incomplete">
                    <Dropdown.Item as={Button}>API Incomplete</Dropdown.Item>
                </NavLink>
                <NavLink href="/broker-api-docs">
                    <Dropdown.Item as={Button}>Broker API Info</Dropdown.Item>
                </NavLink>
                <NavLink href="/broker-enrollments">
                    <Dropdown.Item as={Button}>Broker Signup Enrollments</Dropdown.Item>
                </NavLink>
                <NavLink href="/broker-incomplete">
                    <Dropdown.Item as={Button}>Broker Signup Incomplete</Dropdown.Item>
                </NavLink>

            </DropdownButton>
            <DropdownButton variant="danger" className="ml-auto" drop="left" title="Enrollments">
                {/*<NavLink href="/active">
                    <Dropdown.Item as="button">Active</Dropdown.Item>
                </NavLink> */}
                <NavLink href="/incomplete">
                    <Dropdown.Item as={Button}>Incomplete</Dropdown.Item>
                </NavLink>
                <NavLink href="/enrollments">
                    <Dropdown.Item as={Button}>Enrollments</Dropdown.Item>
                </NavLink>
                <NavLink href="/fraud-review">
                    <Dropdown.Item as={Button}>Fraud Review</Dropdown.Item>
                </NavLink>
                <NavLink href="/enrollment-portal">
                    <Dropdown.Item as={Button}>Enrollment Portal</Dropdown.Item>
                </NavLink>
                <NavLink href="/new-enrollment">
                    <Dropdown.Item as={Button}>New Enrollment</Dropdown.Item>
                </NavLink>
                <NavLink href="/dropped">
                    <Dropdown.Item as={Button}>Saves Report</Dropdown.Item>
                </NavLink>
                <NavLink href="/enrollments/qs-db-04">
                    <Dropdown.Item as={Button}>QS Dashboard</Dropdown.Item>
                </NavLink>
                {/*<NavLink href="/deposit-required">
                    <Dropdown.Item as="button">Deposit Required</Dropdown.Item>
                </NavLink>*/}
            </DropdownButton>
            <DropdownButton  variant="danger" className="ml-auto" drop="left" title="Accounts">
                <NavLink href="/account/lookup">
                    <Dropdown.Item as={Button}>Lookup</Dropdown.Item>
                </NavLink>
                <NavLink href="/account/pending-myaccount">
                    <Dropdown.Item as={Button}>Pending MyAccount</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton
                variant="danger"
                className="ml-auto"
                drop="left"
                title="Renewals"
            >
                <NavLink href="/renewals">
                    <Dropdown.Item as={Button}>Renewals</Dropdown.Item>
                </NavLink>
                <NavLink href="/renewals/qs-db-03">
                    <Dropdown.Item as={Button}>QS Dashboard</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton variant="danger" className="ml-auto" drop="left" title="Partners">
                <NavLink href="/partner-list">
                    <Dropdown.Item as={Button}>Partners List</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton variant="danger" className="ml-auto" drop="left" title="Accounting">
                <NavLink href="/accounting/qs-db-05">
                    <Dropdown.Item as={Button}>QS Tax Reconciliation</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton variant="danger" className="ml-auto" drop="left" title="Tools">
                <NavLink href="/generate-email-signature">
                    <Dropdown.Item as={Button}>Generate Email Signature</Dropdown.Item>
                </NavLink>
                <NavLink href="/settings/enrollment" disabled={disablePrices}>
                    <Dropdown.Item as={Button}>Enrollment Settings</Dropdown.Item>
                </NavLink>
                {/*<NavLink href="/products">
                    <Dropdown.Item as={Button}>Products</Dropdown.Item>
                </NavLink>*/}
                <NavLink href="/promocodes">
                    <Dropdown.Item as={Button}>PromoCodes</Dropdown.Item>
                </NavLink>
                <NavLink href="/sms-templates">
                    <Dropdown.Item as={Button}>SMS Templates</Dropdown.Item>
                </NavLink>
                <NavLink href="/maintenance-banners">
                    <Dropdown.Item as={Button}>Maintenance Banners</Dropdown.Item>
                </NavLink>
                <NavLink href="/settings/checkemail">
                    <Dropdown.Item as={Button}>Check Email Validity</Dropdown.Item>
                </NavLink>
                <NavLink href="/settings/renewalnotice">
                    <Dropdown.Item as={Button}>Renewal Notice</Dropdown.Item>
                </NavLink>
                <NavLink href="/settings/tduforaccount">
                    <Dropdown.Item as={Button}>Tdu for Account</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton variant="danger" className="ml-auto" drop="left" title="VideoVet">
                <NavLink href="/videovet">
                    <Dropdown.Item as={Button}>Chat Log</Dropdown.Item>
                </NavLink>
            </DropdownButton>
            <DropdownButton variant="danger" className="ml-auto" drop="left" title="Tech">
                <NavLink href="/tech/dashapp01">
                    <Dropdown.Item as={Button}>App DashBd 01</Dropdown.Item>
                </NavLink>
                <NavLink href="/tech/dashhdw01">
                    <Dropdown.Item as={Button}>Hdw DashBd 01</Dropdown.Item>
                </NavLink>
                <NavLink href="/tech/tk-review/eh">
                    <Dropdown.Item as={Button}>Tickets EH Review</Dropdown.Item>
                </NavLink>
                <NavLink href="/tech/tk-review/qw">
                    <Dropdown.Item as={Button}>Tickets QW Review</Dropdown.Item>
                </NavLink>
            </DropdownButton>
        </>
    );
}

import React,{ useState, useEffect } from "react";
import axios from "../../utils/axios";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import {FieldLabel, TextField} from "../../components/FormComponents";
import { useMsal } from "@azure/msal-react";
import {Input} from "@mui/material";
import {breakpoints} from "../../components/BreakPoints";
import DataTable from "react-data-table-component";
import RowSelector from "../../components/RowSelector";
import Table from "react-bootstrap/Table";
import jquery from "jquery"
const Lp = styled.div`
    width: 300px;
  text-align: left;
` ;
const Lspan = styled.div`
  width: 110px;
    text-align: right;
    float:left;
` ;
const Rspan = styled.div`
    text-align: left;
  float: right;
` ;
const Column = styled.div`
  flex: 0 0 calc(50% - 4px);

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    flex: 0 0 100%;

    &:first-of-type {
      
    }
  }
`;
const ColumnTitle = styled.div`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 5px;
  height: 35px;
  font-size: 2rem;
  margin-top: 3px;
  margin-bottom: 5px;
  width: 320px;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
`;
const Container = styled.div`
  text-align: center;
  display: inline-block;
    width: 60%;
  align-items: center;
  justify-content: center;
  & .text {
    margin-bottom: 0.5rem;
  }
`;
const Form = styled.form`
  max-width: 70%;
  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 0.5rem auto 0 0;
  & .text {
    margin-bottom: 1.5rem;
  }
`;
const InputGroup = styled.div`
  margin-bottom: 1rem;
`;
const LinkButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 85px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 320px;
        text-align: center;   
        display: inline-block;
        align-items: center;
        justify-content: center;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    `;
const TextInput = styled(Input)`
        width: 20%;
  margin-top: 50px;
        margin-bottom: 12px;
        border-radius: 5px;
        border: 1px solid rgba(0,0,0,0.23);
        padding: 1px 5px;
        font-size: 1rem;
        height: 33px;
    `;
const Row = styled.div`
  
  padding: min(0.75vw, 0.25rem);
  color: #3a3a3a;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.md_end}) {
    padding: 1rem 8vw 4rem;
  }
`;
export const Account =  (props) => {
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] =  useState([]);
    const [message, setMessage] = useState('');
    const [accountData, setAccountData] = useState([]);
    const [accountNumber, setAccountNumber] = useState('');
    const [filterText, setFilterText] = React.useState('');
    const conditionalRowStyles = [
        {
            when: row => row.account_number > 0,
            style: {
                display: "none",
            },
    },
    ];

    const handlePOS = async (e) => {
        console.log(e);
        const  gcid = e.target.attributes.gcid.value;
        const  premid = e.target.attributes.premid.value;
        const url = `https://internal.goodcharlie.com/gb/api/v1/account/profile/${gcid}/premise/${premid}/docs/pos`;
        fetch(url, {headers: {'x-api-key': process.env.REACT_APP_API_KEY}} ) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
            console.log(err);
        });
    };
    const handleLOC = async (e) => {
        console.log(e);
        const  gcid = e.target.attributes.gcid.value;
        const  premid = e.target.attributes.premid.value;
        const url = `https://internal.goodcharlie.com/gb/api/v1/account/profile/${gcid}/premise/${premid}/docs/loc`;
        fetch(url, {headers: {'x-api-key': process.env.REACT_APP_API_KEY}} ) // FETCH BLOB FROM IT
            .then((response) => response.blob())
            .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
                var _url = window.URL.createObjectURL(blob);
                window.open(_url, "_blank").focus(); // window.open + focus
            }).catch((err) => {
            console.log(err);
        });
    };
    const filteredItems = accountData.filter(
        item => item.account_number && item.account_number.toLowerCase().includes(filterText.toLowerCase()),
    );
    const ExpandedComponentGC = ({ data }) => <Table  key={"tbl_" + data.account_id} striped bordered hover  responsive="sm">
        <tbody>
        <tr>
            <td><b>AccountNumber</b><br/>{data.account_number}</td>
            <td><b>GCId</b><br/>{data.gc_id}</td>
            <td><b>First Name</b><br/>{data.first_name}</td>
            <td><b>Last Name</b><br/>{data.last_name}</td>
        </tr>
        <tr>
            <td><b>Due</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Due') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Due') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Due') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Due') ? data.gc_balances[3].Amount : ''
            }</td>
            <td><b>Unbilled</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Unbilled') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Unbilled') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Unbilled') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Unbilled') ? data.gc_balances[3].Amount : ''
            }</td>
            <td><b>Balance</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Balance') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Balance') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Balance') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Balance') ? data.gc_balances[3].Amount : ''
            }</td>
            <td><b>Real</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Real') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Real') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Real') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Real') ? data.gc_balances[3].Amount : ''
            }</td>

        </tr>
        <tr>
            <td><b>Email</b><br/>{data.email}</td>
            <td>
                <b>Email Marketing OptIn: </b><br/>{data.optin_marketing_emails ? 'Yes' : 'No'}
            </td>
            <td><b>Phone</b><br/>{data.phone}</td>
            <td>
                <b>SMS Marketing OptIn: </b><br/>{data.optin_marketing_sms ? 'Yes' : 'No'}
            </td>
        </tr>
        <tr>
            <td><b>Registered MyAccount</b><br/>{data.myaccount_registration ? 'Yes' : 'No'}</td>
            <td><b>Referral Code</b><br/>{data.referral_code}</td>
            <td><b>Referred Accounts</b><br/>
               {data.referred_accounts_count}<br/>
                {data.referred_accounts_count ? JSON.stringify(data.referred_accounts) : ''}

            </td>
            <td><b>Rescue Partner</b><br/>{data.rescue_partner.partner_name}</td>
        </tr>
        {data.premises.map((item, index) => {
            return <tr>
                <td><b>Premise : </b>{item.status}<br/>
                    {item.address} {item.city} {item.zipCode}<br/>{item.esiid}<br/>
                    <button key={"pos_" + item.id} className="btn btn-primary" onClick={handlePOS} gcid={data.gc_id}
                            premid={item.servicepoint_id}>Proof of Service
                    </button>
                    <button key={"loc_" + item.id} className="btn btn-primary" onClick={handleLOC} gcid={data.gc_id}
                            premid={item.servicepoint_id}>Letter of Credit
                    </button>
                </td>
                <td>
                    <b>Product: </b><br/>{item.product_name}
                    <br/>
                    <br/>
                    <b>ServicePointId: </b><br/>{item.servicepoint_id}
                </td>
                <td>
                    <b>Start
                        Date: </b><br/>{item.service_start_date ? new Date(item.service_start_date).toDateString() : ''}
                    <br/>
                    <br/>
                    <b>End
                        Date: </b><br/>{item.inactivation_date ? new Date(item.inactivation_date).toDateString() : ''}
                </td>
                <td>
                    <b>Scheduled Service Action: </b><br/>{item.scheduled_action}
                    <br/>
                    <br/>
                    <b>Scheduled Service Action
                        Date: </b><br/>{item.scheduled_action_date ? new Date(item.scheduled_action_date).toDateString() : ''}
                </td>
            </tr>
            }
        )}
        </tbody>
    </Table>;
    const ExpandedComponentSG = ({ data }) => <Table  key={"tbl_" + data.account_id} striped bordered hover  responsive="sm">
        <tbody>
        <tr>
            <td><b>AccountNumber</b><br/>{data.account_number}</td>
            <td><b>GCId</b><br/>{data.gc_id}</td>
            <td><b>First Name</b><br/>{data.first_name}</td>
            <td><b>Last Name</b><br/>{data.last_name}</td>
        </tr>
        <tr>
            <td><b>Due</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Due') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Due') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Due') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Due') ? data.gc_balances[3].Amount : ''
            }</td>
            <td><b>Unbilled</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Unbilled') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Unbilled') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Unbilled') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Unbilled') ? data.gc_balances[3].Amount : ''
            }</td>
            <td><b>Balance</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Balance') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Balance') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Balance') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Balance') ? data.gc_balances[3].Amount : ''
            }</td>
            <td><b>Real</b><br/>{
                (data.gc_balances[0] && data.gc_balances[0].aType === 'Real') ? data.gc_balances[0].Amount :
                    (data.gc_balances[1] && data.gc_balances[1].aType === 'Real') ? data.gc_balances[1].Amount :
                        (data.gc_balances[2] && data.gc_balances[2].aType === 'Real') ? data.gc_balances[2].Amount :
                            (data.gc_balances[3] && data.gc_balances[3].aType === 'Real') ? data.gc_balances[3].Amount : ''
            }</td>

        </tr>
        <tr>
            <td><b>Email</b><br/>{data.email}</td>
            <td>
                <b>Email Marketing OptIn: </b><br/>{data.optin_marketing_emails ? 'Yes' : 'No'}
            </td>
            <td><b>Phone</b><br/>{data.phone}</td>
            <td>
                <b>SMS Marketing OptIn: </b><br/>{data.optin_marketing_sms ? 'Yes' : 'No'}
            </td>
        </tr>
        <tr>
            <td><b>Registered MyAccount</b><br/>{data.myaccount_registration ? 'Yes' : 'No'}</td>
            <td><b>Referral Code</b><br/>{data.referral_code}</td>
            <td><b>Referred Accounts</b><br/>
                {data.referred_accounts_count}<br/>
                {data.referred_accounts_count ? JSON.stringify(data.referred_accounts) : ''}

            </td>
            <td><b>Rescue Partner</b><br/>{data.rescue_partner.partner_name}</td>
        </tr>
        {data.premises.map((item, index) => {
                return <tr>
                    <td><b>Premise : </b>{item.status}<br/>
                        {item.address} {item.city} {item.zipCode}<br/>{item.esiid}<br/>
                        <button key={"pos_" + item.id} className="btn btn-primary" onClick={handlePOS} gcid={data.gc_id}
                                premid={item.servicepoint_id}>Proof of Service
                        </button>
                        <button key={"loc_" + item.id} className="btn btn-primary" onClick={handleLOC} gcid={data.gc_id}
                                premid={item.servicepoint_id}>Letter of Credit
                        </button>
                    </td>
                    <td>
                        <b>Product: </b><br/>{item.product_name}
                        <br/>
                        <br/>
                        <b>ServicePointId: </b><br/>{item.servicepoint_id}
                    </td>
                    <td>
                        <b>Start
                            Date: </b><br/>{item.service_start_date ? new Date(item.service_start_date).toDateString() : ''}
                        <br/>
                        <br/>
                        <b>End
                            Date: </b><br/>{item.inactivation_date ? new Date(item.inactivation_date).toDateString() : ''}
                    </td>
                    <td>
                        <b>Scheduled Service Action: </b><br/>{item.scheduled_action}
                        <br/>
                        <br/>
                        <b>Scheduled Service Action
                            Date: </b><br/>{item.scheduled_action_date ? new Date(item.scheduled_action_date).toDateString() : ''}
                    </td>
                </tr>
            }
        )}
        </tbody>
    </Table>;
    let ExpandedComponent = ExpandedComponentGC;
    const loadAccount = async (accountNumber) => {
        console.log('loadAccount');
        console.log(accountNumber);
        let response = await axios.get(`/internal/account/${accountNumber}`,
            {headers: {'x-api-key': process.env.REACT_APP_API_KEY}}
        );
        console.log(response.data.account)
        if (response.data?.account?.length === 0) {
            ExpandedComponent = ExpandedComponentSG;
        } else {
            ExpandedComponent =ExpandedComponentGC;
        }
        await setAccountData(response.data.account);
        await setColumns([
            {
                name: '',
                selector: row => null,
                sortable: false,
                right: false,
                style: "padding:1px;margin:1px;",
                width: "2%",
            }
        ]);
        setLoading(false);
    }
    function HandleAccountInputChange(event) {
        console.log('HandleAccountInputChange')
        console.log(event.target.value);
        setAccountNumber( event.target.value );
    }
    function doBack()
    {
        setAccountData([]);
    }
    function HandleSubmit(event) {
        event.preventDefault();
        if (accountNumber.length > 0) {
            console.log(accountNumber);
            loadAccount(accountNumber);
        } else {
            console.log('accountNumber empty');
        }

    }

    if (accountData.length === 0 ) {
        return (
            <>
                <main>
                    <Row>
                        <Column>
                            <ColumnTitle>Account Search</ColumnTitle><br/>
                            <Container>
                                <Form onSubmit={HandleSubmit}>
                                    <InputGroup>
                                        <FieldLabel htmlFor="compareAverage">Account Number</FieldLabel>
                                        <TextField
                                            key={999}
                                            type = {"text"}
                                            id = {"accountNumber"}
                                            name = { "accountNumber"}
                                            disabled={false}
                                            value={accountNumber}
                                            onChange={HandleAccountInputChange}

                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Button type="submit"> Submit </Button>
                                    </InputGroup>
                                    <InputGroup>
                                        {message}
                                    </InputGroup>
                                </Form>
                            </Container>
                        </Column>
                    </Row>
                </main>
            </>
        );
    }
        return (
            <>
                <main>
                    <Row>
                        <Column>
                            <ColumnTitle>Account Search</ColumnTitle><br/>
                            <Container>
                                <Form onSubmit={HandleSubmit}>
                                    <InputGroup>
                                        <FieldLabel htmlFor="compareAverage">Account Number</FieldLabel>
                                        <TextField
                                            key={999}
                                            type = {"text"}
                                            id = {"accountNumber"}
                                            name = { "accountNumber"}
                                            disabled={false}
                                            value={accountNumber}
                                            onChange={HandleAccountInputChange}

                                        />
                                        <Button type="submit"> Submit </Button>
                                        {message}
                                    </InputGroup>
                                </Form>
                            </Container>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <Container>
                                {(accountData.length === 0 && loading) ? (
                                    <>loading</>
                                ) : (

                                    <DataTable
                                        className={"table-responsive-lg"}
                                        columns={columns}
                                        conditionalRowStyles={conditionalRowStyles}
                                        data={filteredItems}
                                        defaultSortAsc={false}
                                        defaultSortFieldId={1}
                                        expandableRows={true}
                                        expandableRowExpanded={row => true}
                                        expandableRowsComponent={ExpandedComponent}
                                        fixedHeaderScrollHeight="1000px"

                                        progressPending={loading}
                                        striped
                                        responsive
                                        dense

                                    >
                                    </DataTable>

                                )}
                            </Container>
                        </Column>
                    </Row>

                </main>
            </>
        )
}
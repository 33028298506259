import { Checkbox, Radio } from "@mui/material";
import { useEffect, useState } from "react";

const checkInArray = (arr, obj, property) => {
    let selected = false;
    for (let i = 0; i < arr?.length; i++) {
        let data = arr[i];
        if (obj[property] === data[property]) {
            return true;
        } else {
            selected = false
        }
    }
    return selected;
}
export const handleUnselect = (row, tableData) => {
    console.log('handleUnselect')
    console.log(row);
    console.log(tableData);
    let my = document.getElementById(`chk_${row.AccountID}`)
    console.log(my);
    if (my) {
        my.click();
    }
}
const OneRowSelector = ({ row, tableData, setTableData , handler}) => {
    if (row === null) {
        return <div />
    }
    //let selected = checkInArray(tableData, row, "id");
    const [isSelected, setSelected] = useState(false);

    const handleSelect = (row,isSel,ltable) => {
        if (isSel) {
            console.log('C')
            let arr = [];
            console.log(typeof  tableData)
            tableData?.map((data,i)=>{
                console.log(i)
                console.log(data);
                console.log(data.AccountID,row.AccountID)
                if (data.AccountID === row.AccountID) {
                    console.log('pushed');
                    tableData.splice(i,1);
                }
                console.log(arr,'arr')
            });
            console.log(arr)
            setTableData(tableData);

            setSelected(false);
        } else if ( !isSel) {
            if (tableData.length >  0 ) {
                console.log(tableData.length,'my');
                console.log(row,'my');
                let selected = checkInArray(tableData, row, "AccountID");
                if (selected) {
                    setSelected(true);
                    handler();
                }
                //setTableData(tableData);
            } else {
                console.log(tableData.length);
                console.log('B')
                console.log(tableData)
                tableData.push(row);
                setTableData(tableData);
                //tableData.push(row);
                setSelected(true);
                handler();
            }
        } else {
            console.log('?')
           // setTableData([]);
           // setSelected(false);
        }
    }

    return (
        <>
            <Checkbox
                id={`chk_${row.AccountID}`}
                type="checkbox"
                style={{ transform: "scale(1)", padding: 0 }}
                checked={isSelected}
                onClick={() => handleSelect(row, isSelected)}
            />
        </>
    )
}

export default OneRowSelector;
